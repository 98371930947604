.Invite {
  background-color: #f6f2ff;
  border-radius: 4px;
  margin-top: 24px;
  display: flex;
  overflow: hidden;

  &__content {
    width: 70%;
    padding: 24px 0 12px 24px;

    &--text {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
    }

    &--button {
      margin-top: 16px;
      display: inline-block;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      background-color: #7f61c0;
      color: white;
      padding: 9px 19px 10px 18px;
      border-radius: 4px;
    }
  }
}
