.Leaderboards {
  padding: 0;
  &__Banner {
    margin-top: 49px;
    background-image: url('https://d2sqt6igbl7quy.cloudfront.net/classplus-websites/common/images/InnerBackground.svg');
    background-size: auto;
    background-position: top;
    background-repeat: no-repeat;

    padding: 24px;

    &--Head {
      font-style: normal;
      font-weight: 900;
      font-size: 22px;
      line-height: 28px;
      /* identical to box height */

      color: #ffffff;
    }
    &--Text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      /* or 18px */

      color: #ffffff;

      margin-top: 8px;
    }
  }
}

.Pointstable {
  &__Head {
    background-color: #f1f1f1;

    padding: 16px;

    display: grid;
    grid-template-columns: 1fr 2fr 1fr 2fr;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    /* identical to box height, or 87% */

    color: rgba(0, 0, 0, 0.87);
  }

  &__Body {
    background-color: #ffffff;

    &__Item {
      padding: 24px 22px;
      display: grid;
      grid-template-columns: 1fr 2fr 1fr 2fr;
      align-items: center;

      font-style: normal;
      font-weight: 450;
      font-size: 16px;
      line-height: 20px;

      color: rgba(0, 0, 0, 0.87);

      &--Results {
        img {
          margin: 0 2px;
        }
      }
    }
  }
}
