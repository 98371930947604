.Banner {
  background-image: url(../../Background.svg);
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  padding: 0 0 24px 0;

  &__text {
    color: #ffffff;
    padding: 24px 0 16px 24px;

    &--head {
      font-weight: 900;
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 8px;
    }

    &--body {
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      padding: 0 130px 0 0;
    }
  }

  &__viewrules {
    display: inline-block;
    background-color: white;
    border-radius: 0 120px 120px 0;
    color: #183687;
    font-size: 14px;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 9px 34px 10px 24px;
    &--img {
      padding-left: 16px;
    }
  }
}

// .Banner__text--body {
//   padding-top: 3%;
//   font-size: 14px;
// }
