.Rules {
  &__Top {
    border-bottom: 1px solid #e5e5e5;

    &--item {
      display: flex;
      margin-bottom: 24px;

      &--bullet {
        background-color: #ff8d00;
        padding: 0 9px 0 9px;
        margin-top: 3px;
        height: 25px;
        width: 25px;
        border-radius: 120px;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
      }

      &--text {
        padding-left: 16px;
        font-weight: 450;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }

  &__Bottom {
    margin-top: 24px;
    font-weight: 450;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
  }
}
