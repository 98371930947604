.Header {
  position: fixed;
  top: 0;
  display: flex;

  background-color: white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  width: 100%;

  align-items: center;

  padding: 12px 16px;

  &__Back {
    margin-right: 28px;
  }

  &__Title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    color: rgba(0, 0, 0, 0.87);
  }
}
