.Prediction {
  &__Teams {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;

    &__Team {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      border: 1px solid #e5e5e5;
      border-radius: 4px;

      padding: 12px 8px;

      &--Details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &--Name {
          margin-top: 12px;

          font-style: normal;
          font-weight: 450;
          font-size: 14px;
          line-height: 130%;
          /* or 18px */

          text-align: center;

          color: rgba(0, 0, 0, 0.87);

          //   width: 105px;
        }
      }

      &--Check {
        margin-top: 10px;
        width: 20px;
        height: 20px;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: #e5e5e5;

        border-radius: 50%;
      }
    }
  }
  &__Poll {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;

    &--Badge {
      background: #f1f1f1;
      border-radius: 120px;

      padding: 6px 0;

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      color: rgba(0, 0, 0, 0.6);
    }
  }
}

.TeamSelected {
  background: rgba(62, 156, 76, 0.16) !important;
  border: 1px solid #3e9c4c !important;

  .Prediction__Teams__Team--Check {
    background-color: #3e9c4c !important;
  }
}
