.PredictionResult {
  &__Heading {
    font-weight: 700;
    font-size: 22px;
    color: white;
    padding-top: 24px;
    padding-bottom: 16px;
  }

  &__Body {
    background-color: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    padding: 0 0 0 16px;
    display: flex;
    justify-content: space-between;

    &__tick {
      width: 32px;
      height: 32px;
      // padding: 9px 11px;
      // background: #52B060;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 120px;
      align-self: center;

      img {
        width: 100%;
      }
    }

    // &__cross {
    //   width: 32px;
    //   height: 32px;
    //   padding: 9px 11px;
    //   background: #ff4058;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   border-radius: 120px;
    //   align-self: center;
    // }

    &__message {
      padding: 22px 16px 22px 16px;

      &--top {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.87);
      }

      &--bottom {
        margin-top: 8px;
        font-weight: 450;
        font-size: 14px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.6);
      }
    }

    &__team {
      width: 55%;
      background: #535251;
      border-radius: 120px 0 0 120px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      img {
        height: 80px;
        padding: 0 15% 0 0;
      }
    }
  }
}
