.RewardsPage {
  padding: 0;
  height: 100vh;
  &__Banner {
    margin-top: 49px;
    background-image: url(../../RewardBG.svg);
    background-size: auto;
    background-position: top right;
    background-repeat: no-repeat;

    padding: 24px;

    border-bottom: 1px solid #e5e5e5;

    &--Head {
      font-style: normal;
      font-weight: 900;
      font-size: 22px;
      line-height: 28px;
      /* identical to box height */

      color: #ffffff;
    }
    &--Text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      /* or 18px */

      color: #ffffff;

      margin-top: 8px;
    }
  }
}

.RewardList {
  padding: 24px 16px;
  // background-color: #ffffff;
  //   height: 100%;

  &__List {
    &__Item {
      display: flex;
      //   justify-content: space-between;
      align-items: center;

      padding: 20px 16px;

      border-radius: 6px;
      margin-bottom: 8px;
      &__Left {
        padding: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: rgba(255, 255, 255, 0.87);
        border-radius: 5px;

        margin-right: 16px;
      }
      &__Right {
        &__Coupon {
          display: flex;
          justify-content: space-between;
          align-items: center;

          margin: 12px 0;
          padding: 16px;

          border: 1px dashed #ffffff;
          box-sizing: border-box;
          border-radius: 3px;
          &--Code {
            &--Label {
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 120%;
              /* or 14px */

              color: rgba(255, 255, 255, 0.8);

              margin-bottom: 4px;
            }
            &--Key {
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
              line-height: 120%;
              /* identical to box height, or 17px */

              color: #ffffff;
            }
          }

          &--Copy {
            background: #333333;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
            border-radius: 4px;

            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            /* identical to box height */

            color: #ffffff;

            padding: 12px 24px;
          }
        }

        &--Offer {
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 150%;
          /* or 21px */

          color: #ffffff;

          display: flex;
          // justify-content: center;
          align-items: center;

          img {
            padding: 8px;
            background-color: rgba(255, 255, 255, 0.87);
            border-radius: 5px;

            margin-right: 16px;
          }
        }
        &--How {
          margin-top: 8px;
          display: flex;
          align-items: center;

          font-style: normal;
          font-weight: 450;
          font-size: 12px;
          line-height: 120%;
          /* or 14px */

          color: #ffffff;

          img {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
