.ViewRewards{
    background: #FFEAE9;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    padding: 22px 26px 21px 24px;

    &__text{
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: rgba(0, 0, 0, 0.87);
    }
}