.LeaderBoardCard {
  background: #fff6ee;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  margin-top: 24px;
  display: flex;
  overflow: hidden;

  &__content {
    width: 70%;
    padding: 24px 0 12px 24px;

    &--text {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
    }

    &--button {
      margin-top: 16px;
      display: inline-block;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      background-color: #bd7332;
      color: #ffffff;
      padding: 9px 19px 10px 18px;
      border-radius: 4px;
    }
  }
}
