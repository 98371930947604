.Rewards {
  border-radius: 4px;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  overflow: hidden;

  &__content {
    font-weight: 500;
    width: 70%;
    padding: 24px 0 0 24px;

    &--text {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
    }

    &--button {
      margin-top: 16px;
      margin-bottom: 24px;
      display: inline-block;
      font-size: 14px;
      background-color: #c3443d;
      color: #ffffff;
      padding: 9px 19px 10px 18px;
      border-radius: 4px;
    }

    &--predicted {
      color: #ffffff;

      &--text {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        display: flex;
        align-items: center;
      }

      &--time {
        margin-top: 8px;
        font-weight: 450;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
      }
    }
  }

  &__Image {
    height: initial;
    max-height: 147px;
  }
}
