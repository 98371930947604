.Redeem {
  // position: fixed;
  // height: 100vh;
  // width: 100vw;
  // left: 0;
  // bottom: 0;
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-end;
  // z-index: 10;

  &__Redeem {
    margin-bottom: 50px;

    &__Label {
      display: flex;
      flex-direction: row;

      align-items: center;

      margin-bottom: 26px;
      img {
        margin-right: 10px;
      }
    }

    &__Body {
      &--Item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */

        color: rgba(0, 0, 0, 0.7);

        margin-bottom: 16px;

        img {
          margin-right: 10px;

          position: relative;
          top: 8px;
        }
      }
    }
  }
}
