.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.LoaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 40vh;
}

.Error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 60vh;
}

.Error--Text {
  color: #ffffff;

  font-size: 20px;
  line-height: 150%;
  font-weight: 600;
  margin-bottom: 12px;
}
.Error--Subtext {
  color: #ff4058;

  font-size: 14px;
  line-height: 150%;
  font-weight: 500;

  text-decoration: underline;
}
