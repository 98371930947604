.Modal {
  position: fixed;
  left: 0;
  top: 0;

  width: 100vw;
  height: 100vh;

  background-color: rgba($color: #000000, $alpha: 0.8);

  z-index: 10;

  &__Container {
    background-color: white;

    border-radius: 16px 16px 0 0;

    position: absolute;
    bottom: 0;

    width: 100%;

    &__Head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 16px 24px;

      border-bottom: 1px solid rgba(0, 0, 0, 0.16);

      &--Title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;

        color: rgba(0, 0, 0, 0.87);

        mix-blend-mode: normal;
      }
    }

    &__Body {
      display: block;
      min-height: 55vh;
      max-height: 65vh;

      overflow-y: auto;

      padding: 24px 16px 36px;
    }

    &__Foot {
      box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.16);
      padding: 16px 24px;

      &--Timer {
        padding: 0 0 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        font-style: normal;
        font-weight: 450;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height, or 150% */
        color: rgba(0, 0, 0, 0.87);

        img {
          margin-right: 8px;
          width: 13px;
        }
      }
      &--Button {
        width: 100%;

        border: none;
        outline: none;

        padding: 12px;
        background-color: #604ad6;
        color: #ffffff;

        border-radius: 4px;

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        &:disabled {
          background-color: #e5e5e5;
          color: #999999;
        }
      }
    }
  }
}
