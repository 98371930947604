.AwardList {
  &__Top {
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */

    display: flex;
    align-items: center;

    color: rgba(0, 0, 0, 0.87);

    padding-bottom: 16px;

    border-bottom: 1px solid #e5e5e5;

    background-image: url(../../ModalBG.svg);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;

    width: calc(100vw - 16px);
  }

  &__Body {
    &--Item {
      margin-top: 16px;
      border-radius: 6px;

      padding: 16px;

      display: flex;
      // justify-content: space-around;
      align-items: center;

      &--Image {
        padding: 8px;
        background-color: rgba(255, 255, 255, 0.87);
        border-radius: 5px;

        margin-right: 16px;
        height: 50px;
        min-width: 50px;
        max-width: 50px;

        img {
          width: 100%;
        }
      }

      &--Text {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        /* or 21px */

        color: #ffffff;
      }
    }
  }
}
