.carouselComponent {
  &__header {
    padding: 40px 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 22px;
      line-height: 130%;
      text-align: center;
      color: rgba(255, 255, 255, 0.87);
    }
    p {
      margin-top: 8px;
      font-size: 16px;
      line-height: 130%;
      text-align: center;
      color: rgba(255, 255, 255, 0.6);
    }
  }

  &__slick {
    width: 100vw;
    overflow: hidden;
    &__cardContainer {
      padding: 20px 12px 30px;
    }
  }
}

.carouselCard {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15), 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 20px;
    border-radius: 8px 8px 0 0;
    height: 300px;
    &__img {
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 150px;
      }
    }
    h1 {
      font-size: 22px;
      line-height: 28px;
      color: #ffffff;
      text-align: center;
    }
    h4 {
      font-size: 14px;
      line-height: 120%;
      color: #ffffff;
      text-align: center;
      margin-top: 6px;
    }
  }
  &__footer {
    padding: 22px 24px;
    button {
      padding: 8px 36px;
      font-size: 18px;
      line-height: 150%;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #009ae0;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      border: none;
      width: 100%;
    }
  }
}

.slick-active {
  transition: 0.25s transform ease-in-out;
  transform: scale3d(1.1, 1.1, 1.1);
}
