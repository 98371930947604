@import './resources/main.scss';

@font-face {
  font-family: Circular;
  src: url(./resources/Circular.otf);
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Circular Std', sans-serif;
  background-color: #1c1643;
  background-image: url(./resources/MainBG.svg);
  background-size: cover;
  font-family: Circular;
}

// APP THEME
.App {
  // box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Circular;

  min-height: 100vh;
}

* {
  box-sizing: border-box !important;
}

.ErrorPopUp {
  position: fixed;
  bottom: 20%;
  padding: 8px;

  border-radius: 6px;

  align-self: center;
  background-color: rgba($color: #000000, $alpha: 0.6);
  color: #ffffff;

  font-size: 14px;
  font-weight: 500;

  white-space: nowrap;

  z-index: 15;
}
