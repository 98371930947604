.coupons {
  padding: 16px;
  h1 {
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: rgba(255, 255, 255, 0.87);
  }
}

.coupon {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15), 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 16px;
  margin: 24px 0;
  &__header {
    display: flex;
    align-items: center;
    &__img {
      height: 50px;
      background: rgba(255, 255, 255, 0.87);
      border-radius: 5px;
      padding: 8px;
      min-width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: auto;
        height: 100%;
      }
    }
    p {
      margin: 0;
      font-size: 16px;
      line-height: 120%;
      color: #ffffff;
      margin-left: 16px;
    }
  }
  &__main {
    border: 1px dashed #ffffff;
    border-radius: 3px;
    margin: 30px 0 25px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__code {
      p {
        font-size: 12px;
        line-height: 120%;
        color: rgba(255, 255, 255, 0.8);
      }
      h3 {
        font-weight: 500;
        font-size: 14px;
        line-height: 120%;
        color: #ffffff;
        margin-top: 4px;
      }
    }
    button {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      padding: 12px 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #333333;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      border: none;
      min-width: 120px;
      transition: all 0.25s;
    }
    button.copied {
      opacity: 0.6;
      pointer-events: none;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    cursor: pointer;
    p {
      margin-left: 10px;
      font-weight: 450;
      font-size: 12px;
      line-height: 120%;
      color: #ffffff;
    }
  }
}

.bottomDrawerContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 500;
  .bottomDrawer {
    width: 100vw;
    position: fixed;
    bottom: -1000px;
    transition: all 0.5s ease-in-out;
    left: 0;
    background: #ffffff;
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.12);
    border-radius: 12px 12px 0px 0px;
    &__header {
      padding: 26px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.16);
      span {
        font-size: 18px;
        line-height: 23px;
        color: rgba(0, 0, 0, 0.87);
      }
      img {
        cursor: pointer;
      }
    }
    &__content {
      max-height: 70vh;
      overflow: scroll;
      padding: 0 24px;
      &__block {
        padding: 25px 0;
        &__header {
          display: flex;
          align-items: center;
          p {
            font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            color: rgba(0, 0, 0, 0.87);
            margin-left: 10px;
          }
        }
        ul {
          img {
            display: none;
          }
          li {
            margin: 16px 24px;
            font-size: 16px;
            line-height: 150%;
            color: rgba(0, 0, 0, 0.7);
            overflow-wrap: break-word;
          }
        }
      }
    }
  }
}
