.Match {
  margin-right: auto;
  margin-left: auto;
  margin-top: 24px;

  // background-color: #ffffff;
  border-radius: 6px;
  &__Heading {
    font-weight: 700;
    font-size: 22px;
    color: white;
    padding-bottom: 16px;
  }

  &__Details {
    height: 50%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: white;
    border-radius: 4px 4px 0 0;

    &--left {
      width: 35%;
      padding: 36px 0 20px 0;

      &--img {
        height: 50px;
        border-radius: 0 120px 120px 0;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 24px;

        img {
          max-height: 80px;
          // width: 55px;
          max-width: 90px;
          margin-top: -24px;
        }
      }

      &--team {
        padding: 0 0 0 11px;
        // margin-left: 11px;
        font-weight: 450;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: rgba(0, 0, 0, 0.87);

        white-space: nowrap;

        @media (max-width: 380px) {
          font-size: 12px;
        }
      }

      &--Selected {
        margin-top: 8px;
        margin-left: 11px;

        display: flex;
        align-items: center;
        justify-content: center;

        &--Badge {
          background: #52b060;
          border-radius: 4px;

          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 24px;
          /* identical to box height, or 200% */

          color: #ffffff;

          width: fit-content;

          padding: 0 10px;
        }
      }
    }

    &--center {
      width: 30%;
      margin-top: 36px;

      &--top {
        font-weight: 900;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        color: rgba(0, 0, 0, 0.6);
      }

      &--bottom {
        font-weight: 450;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: rgba(0, 0, 0, 0.6);
      }
    }

    &--right {
      width: 35%;
      padding: 36px 0 20px 0;

      &--img {
        height: 50px;
        border-radius: 120px 0 0 120px;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 24px;

        img {
          max-height: 80px;
          // width: 55px;
          max-width: 90px;
          margin-top: -24px;
        }
      }

      &--team {
        padding: 0 11px 0 0;
        // margin-right: 11px;
        font-weight: 450;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: rgba(0, 0, 0, 0.87);

        white-space: nowrap;

        @media (max-width: 380px) {
          font-size: 12px;
        }
      }

      &--Selected {
        margin-top: 8px;
        margin-right: 11px;

        display: flex;
        align-items: center;
        justify-content: center;

        &--Badge {
          background: #52b060;
          border-radius: 4px;

          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 24px;
          /* identical to box height, or 200% */

          color: #ffffff;

          width: fit-content;

          padding: 0 10px;
        }
      }
    }
  }

  &__Predict {
    background-color: #c3443d;
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 5px 5px;
    padding: 14px 0;
  }

  &__Predicted {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    display: flex;
    align-items: center;
    justify-content: center;

    color: rgba(0, 0, 0, 0.4);

    background: #f1f1f1;
    border-radius: 0px 0px 4px 4px;

    padding: 6px 0;
  }

  &__Missed {
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 130%;
    /* or 16px */

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    color: rgba(0, 0, 0, 0.87);
    background: #ffe0e4;
    border-radius: 0px 0px 4px 4px;

    padding: 6px 0;
  }
}
