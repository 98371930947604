.Quiz {
  &__Heading {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    /* identical to box height */

    color: #ffffff;

    margin-bottom: 16px;

    // margin-top: 24px;
  }
  &__Body {
    background-color: #ffffff;
    border-radius: 4px;
    overflow: hidden;
    &__Content {
      padding: 24px 16px;
      font-style: normal;

      &--Question {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        /* or 21px */

        color: rgba(0, 0, 0, 0.87);

        padding-bottom: 32px;
        border-bottom: 1px solid #e5e5e5;
        margin-bottom: 16px;
      }
      &--Options {
        &--Point {
          background: #ffffff;
          border: 1px solid #cccccc;

          margin-bottom: 8px;

          padding: 14px 12px;

          display: flex;
          align-items: center;

          border-radius: 4px;

          &:last-child {
            margin-bottom: 0;
          }

          input {
            margin: 0;
            margin-right: 16px;
          }
          &--Text {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
            /* identical to box height, or 21px */

            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
    &__Bottom {
      padding: 14px;

      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      /* identical to box height, or 24px */

      display: flex;
      justify-content: center;
      align-items: center;

      color: #ffffff;

      width: 100%;
      outline: none;
      border: none;

      background-color: #5993ee;

      &:disabled {
        background-color: #cccccc;
      }
    }
  }
}

.Selected {
  background: rgba(89, 147, 238, 0.04) !important;
  border: 1px solid #5993ee !important;
}
